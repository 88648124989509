.searchForm {
  margin-left: 40%;
  justify-content: center;


}



.paginationBttns {

  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-left: 12%;


}

.paginationBttns a {

  padding: 8px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #2b2eff;
  color: #2b2eff;
  cursor: pointer;



}

.paginationBttns a:hover {
  color: white;
  background-color: #2b2eff;


}

.paginationActive a {
  color: white;
  background-color: #2b2eff;

}

.flex-parent {

  display: flex;
  flex-wrap: wrap;
  margin-left: 7%;
}

.flex-child {
  flex: 1 0 25%;
  align-content: center;
  margin: 5px;

}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;

}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  color: black;
}

.flip-card-back {
  color: black;
  transform: rotateY(180deg);
}