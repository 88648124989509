/* Home.css */

.b {
    margin: 20px;
  }
  
  /* New styles for the enhanced home page */
  .card {
    background-image: url(./mural.jpg);
    background-size: cover;
    color: white;
  }
  
  .title {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
  }
  
  .header {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .subTitle {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  
  .buttonGroup {
    display: flex;
    justify-content: center;
  }
  
  .button {
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
