
/* Existing styles for the navbar */
nav {
  background-color: whitesmoke;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.nav-item {
  width: 100px;
  text-align: center;
  width: 100%;
  margin: 10px;
}

nav a {
  color: black;
  text-decoration: none;
  text-align: right;
}

/* Enhanced styles for the navbar */
nav a.active {
  color: burlywood;
  font-weight: bold;
  border-bottom: 3px solid burlywood; /* Reduce the border size */
  box-sizing: border-box;
  transition: color 0.3s, border-bottom 0.3s; /* Add transition for smoother hover effect */
}

nav a.active:hover {
  color: green !important;
  border-bottom: 3px solid green !important;
}

nav a:hover {
  text-decoration: none;
  color: brown;
}

/* Remove the border-bottom for the active navbar-brand */
nav a.active.navbar-brand {
  border-bottom: none;
}

/* Increase font weight on hover for non-brand links */
nav a:not(.navbar-brand):hover {
  font-weight: bold;
}

