body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: left;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/* Home.css */

.b {
    margin: 20px;
  }
  
  /* New styles for the enhanced home page */
  .card {
    background-image: url(/static/media/mural.f3838bc4.jpg);
    background-size: cover;
    color: white;
  }
  
  .title {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
  }
  
  .header {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .subTitle {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  
  .buttonGroup {
    display: flex;
    justify-content: center;
  }
  
  .button {
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: bold;
  }
  


/* Existing styles for the navbar */
nav {
  background-color: whitesmoke;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.nav-item {
  width: 100px;
  text-align: center;
  width: 100%;
  margin: 10px;
}

nav a {
  color: black;
  text-decoration: none;
  text-align: right;
}

/* Enhanced styles for the navbar */
nav a.active {
  color: burlywood;
  font-weight: bold;
  border-bottom: 3px solid burlywood; /* Reduce the border size */
  box-sizing: border-box;
  transition: color 0.3s, border-bottom 0.3s; /* Add transition for smoother hover effect */
}

nav a.active:hover {
  color: green !important;
  border-bottom: 3px solid green !important;
}

nav a:hover {
  text-decoration: none;
  color: brown;
}

/* Remove the border-bottom for the active navbar-brand */
nav a.active.navbar-brand {
  border-bottom: none;
}

/* Increase font weight on hover for non-brand links */
nav a:not(.navbar-brand):hover {
  font-weight: bold;
}


.searchForm {
  margin-left: 40%;
  justify-content: center;


}



.paginationBttns {

  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-left: 12%;


}

.paginationBttns a {

  padding: 8px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #2b2eff;
  color: #2b2eff;
  cursor: pointer;



}

.paginationBttns a:hover {
  color: white;
  background-color: #2b2eff;


}

.paginationActive a {
  color: white;
  background-color: #2b2eff;

}

.flex-parent {

  display: flex;
  flex-wrap: wrap;
  margin-left: 7%;
}

.flex-child {
  flex: 1 0 25%;
  align-content: center;
  margin: 5px;

}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;

}

.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  color: black;
}

.flip-card-back {
  color: black;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.i {
    max-width: 35%;

}

form {
    margin-top: 5%;

    margin-left: 25%;
}
